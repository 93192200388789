import logo from './logo.png';
import React, { useState,useEffect } from 'react';
import './App.css';
import Header from "./components/Header";
import SideBar from "./components/SideBar";
import Program from "./components/Program";
import Report from "./components/Report";
import Ethics from './components/Ethics';
import Tier from './components/Tier';
import Form from './components/Form';
import {getReportData}  from "./services";
import SuccessPage from './components/SuccessPage';
const App = () => {
   const [selected, setSelected] = useState('Program');
   const [reportData, setReportData] = useState([]);
   
   useEffect(() => {
      getReportData((response) => {
         if(response?.code === 200){
            setReportData(response.data)
         }
      })
    }, []);

   return (
      <div>
         <Header />
         <div class="row m-4">
            <div class="col-md-4 col-sm-6">
               <SideBar setSelected={setSelected} selected={selected} />
            </div>
            <div class="col-md-8 col-sm-6">
               <div className='row firstRow'>
                  {selected === 'Program' && <Program setSelected={setSelected}/>}
               </div>
               <div className='row secondRow'>
                  {selected === 'Report' && <Report reportData={reportData} />}
               </div>
               <div className='row thirdRow'>
                  {selected === 'Ethics' && <Ethics />}
               </div>
               <div className='row fourthRow'>
                  {selected === 'Tier' && <Tier />}
               </div>
               {selected === 'Form' && <Form setSelected={setSelected} />}
               {selected === 'Success' && <SuccessPage setSelected={setSelected} />}
            </div>
         </div>
      </div>
   );
}

export default App;
