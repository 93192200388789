import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import upload_icon from '../upload_icon.png';
import { postFormData } from "../services";
import { FileUploader } from "react-drag-drop-files";
import { Spinner } from 'react-bootstrap';


const fileTypes = ["JPEG", "PNG"];
const Form = (props) => {
   let formData = null;
   const [submitErrorFlag, setSubmitErrorFlag] = useState(false);
   const [loaderFlag, setLoaderFlag] = useState(false);
   const [file, setFile] = useState(null);
   const handleChange = (file) => {
      setFile(file);
   };
   const handleSubmit = (event) => {
      event.preventDefault()
      setSubmitErrorFlag(false)
      setLoaderFlag(true)
      document.getElementsByTagName("body")[0].classList.add('overlay');
      let payload = {
         name: event?.target?.name?.value,
         email: event?.target?.inputEmail?.value,
         videoTitle: event?.target?.videoTitle?.value,
         seasonNumber: event?.target?.seasonNumber.value,
         episodeNumber: event?.target?.episodeNumber.value,
         categoryParentalControl: event?.target?.customCheck2.checked,
         categoryContentRelated: event?.target?.customCheck3.checked,
         categoryAgeDescriptorTagsTitle: event?.target?.customCheck1.checked,
         description: event?.target?.message.value,
         timeCode: event?.target?.contentGrievance.value,
      }
      if (payload.name === '' ||
         payload.email === '' ||
         payload.videoTitle === '' ||
         payload.description === '' ||
         (!payload.categoryAgeDescriptorTagsTitle &&
            !payload.categoryContentRelated &&
            !payload.categoryParentalControl)) {
         setSubmitErrorFlag(true)
         setLoaderFlag(false)
         document.getElementsByTagName("body")[0].classList.remove('overlay');
      } else {
         try {
            formData = new FormData();
            formData.append('name', payload.name)
            formData.append('email', payload.email)
            formData.append('videoTitle', payload.videoTitle)
            formData.append('seasonNumber', payload.seasonNumber)
            formData.append('episodeNumber', payload.episodeNumber)
            formData.append('categoryParentalControl', payload.categoryParentalControl)
            formData.append('categoryContentRelated', payload.categoryContentRelated)
            formData.append('categoryAgeDescriptorTagsTitle', payload.categoryAgeDescriptorTagsTitle)
            formData.append('description', payload.description)
            formData.append('timeCode', payload.timeCode)
            if (file) {
               formData.append("file", file[0]);
            }
            postFormData(formData, (response) => {
               setLoaderFlag(false)
               document.getElementsByTagName("body")[0].classList.remove('overlay');
               if (response?.code === 200) {
                  props.setSelected('Success')
               }
            })
         } catch (error) {

         }

      }
   }
   return (
      <div className='row formDiv'>
         <div className='first'>
            <h6>AMC NETWORKS - CONTENT GRIEVANCE FORM</h6>
            <p className='mt-4 mb-4'>Any complaints related to the content of the titles currently available in India or its age ratings,
               descriptions. content advisories or tags and parental control
               features can be raised by filling the form below -
            </p>
            <form onSubmit={handleSubmit}>
               <div className='col-md-8 col-sm-12 formDisplayInline'>
                  <div className="form-row">
                     <div className='form-group col-md-6 col-sm-12 formPadding formDisplayInline'>
                        <label for="name">Name <span className='red'>*</span></label>
                        <input type="text" className="form-control mt-1" id="name" placeholder="Enter your name here"></input>
                     </div>
                     <div className='form-group col-md-6 col-sm-12 formPadding formDisplayInline'>
                        <label for="inputEmail4">Email-ID <span className='red'>*</span></label>
                        <input type="email" className="form-control mt-1" id="inputEmail" placeholder="Enter your E-mail ID here"></input>
                     </div>
                  </div>

                  <div className="form-row mt-4">
                     <div className='form-group col-md-6 col-sm-12 formPadding formDisplayInline'>
                        <label for="videoTitle">Video Title <span className='red'>*</span></label>
                        <input type="text" className="form-control mt-1" id="videoTitle" placeholder="Enter the Title of Video which is faulty"></input>
                     </div>
                     <div className='form-group col-md-6 col-sm-12 formPadding formDisplayInline'>
                        <label for="seasonNumber">Season Number</label>
                        <input type="text" className="form-control mt-1" id="seasonNumber" placeholder="Enter the season number ( if Applicable )"></input>
                     </div>
                  </div>

                  <div className="form-row mt-4">
                     <div className='form-group col-md-6 col-sm-12 formPadding formDisplayInline'>
                        <label for="episodeNumber">Episode Number</label>
                        <input type="text" className="form-control  mt-1" id="episodeNumber" placeholder="Enter the episode number ( if Applicable )"></input>
                     </div>
                     <div className='form-group col-md-6 col-sm-12 formPadding formDisplayInline'>
                        <label for="contentGrievance">Timecodes of the Content Grievance</label>
                        <input type="text" className="form-control  mt-1" id="contentGrievance" placeholder="Enter the Timecodes ( in “hh:mm:ss” format )"></input>
                     </div>
                  </div>
               </div>

               <div className='col-md-4 formDisplayInline dragDropDivAli smallDeviceHide'>

                  <FileUploader
                     multiple={true}
                     maxSize={5}
                     handleChange={handleChange}
                     name="file"
                     types={fileTypes}
                  >
                     <div className='dragDropDiv'>
                        <div><img className='tickIcon' src={upload_icon} alt="upload_icon" /></div>
                        <p className='paraDrag'>Drag & Drop a screenshot of the content here</p>
                        <p className='paraDrag'>or</p>
                        <button type="button" className="btn btn-dark  mt-3 mb-3">BROWSE FILE</button>
                     </div>
                     {file && <p className='mt-2' style={{ textAlign: 'center' }}>{`Selected File: ${file[0]?.name}`}</p>}
                  </FileUploader>
               </div>

               <div className="form-row mt-4">
                  <div className='form-group col-md-4 col-sm-12 formPadding formDisplayInline'>
                     <label for="contentFiled">Description of the Content Grievance <span className='red'>*</span></label>
                     <textarea className="form-control mt-1" id="message" type="text" placeholder="Message"></textarea>
                  </div>
                  <div className='custom-control custom-checkbox col-md-5 col-sm-12 formPadding formDisplayInline'>
                     <label className="custom-control-label" for="">Category <span className='red'>*</span></label><br />
                     <input type="checkbox" className="custom-control-input" id="customCheck1"></input>
                     <label className="custom-control-label labelCheck" for="customCheck1"> &nbsp; Age Rating/Content Descriptors/Tags/Title Synopsis</label><br />
                     <input type="checkbox" className="custom-control-input" id="customCheck2"></input>
                     <label className="custom-control-label labelCheck" for="customCheck2"> &nbsp; Parental Control Mechanism</label><br />
                     <input type="checkbox" className="custom-control-input" id="customCheck3"></input>
                     <label className="custom-control-label labelCheck" for="customCheck3"> &nbsp; Content Related</label>
                  </div>
               </div>
               <div className="form-row mt-4">
                  <div className='form-group col-md-12 formPadding formDisplayInline'>
                     <div className='bigScreenShow'>
                        <FileUploader
                           multiple={true}
                           maxSize={5}
                           handleChange={handleChange}
                           name="file"
                           types={fileTypes}
                        >
                           <button type="button" className="btn btn-dark  mt-3 mb-3">BROWSE FILE</button>
                           {file && <p className='mb-3' style={{ textAlign: 'center' }}>{`Selected File: ${file[0]?.name}`}</p>}
                        </FileUploader>
                     </div>
                     <button type="submit" className="btn btn-primary submitBtn mt-sm-1" style={{ marginRight: "15px" }}><span className='btnText'>SUBMIT</span></button>
                     <button type="button" className="btn  btn-outline-secondary cancelBtn mt-sm-1" style={{ marginRight: "15px" }}><span className='btnText'>CANCEL</span></button>
                     <button type="reset" onClick={() => { setFile(null); formData = null }} className="btn btn-outline-secondary clearBtn  mt-sm-1"><span className='btnText'>CLEAR FORM</span></button>
                  </div>
                  {submitErrorFlag && <p className='red mt-2'>Please fill all mandatory fields</p>}
                  {
                     loaderFlag && <div style={{ position: "fixed", top: "50%", left: "50%", transform: "translate(-50%, -50%)" }}>
                        <Spinner className="spinner-size" animation="border" variant="info" />
                     </div>
                  }
               </div>
            </form>
         </div>
      </div>
   )
}

export default Form;