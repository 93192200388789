import React from 'react';
import ReactDOM from 'react-dom';

const Ethics = (params) => {
    return (
        <div className='first'>
            <h6>CODE OF ETHICS</h6>
            <p className='paragraphPadding'>The general principles observed by the platform on content related to the Code of Ethics
                set forth by the Ministry of Information and Broadcasting include: Not publishing content prohibited
                under any law or prohibited by any court of competent jurisdiction.</p> 
            <p className='paragraphPadding'> 
                Exercise due caution in respect to
                content that affects the sovereignty and integrity of India threatens, endangers, or jeopardizes
                the security of the State; is detrimental to India‘s friendly relations with foreign countries,
                or is likely to incite violence or disturb the maintenance of public order.</p> 
            <p className='paragraphPadding'> 
                Exercise caution and
                consider India’s multi-racial and multi-religious context when publishing
                content that depicts any racial or religious group’s activities, beliefs and practices.
            </p>
            <h6 style={{margin:"25px 0px"}}>GRIEVANCES RELATED TO CODE OF ETHICS</h6>
            <div className="accordion accordion-flush" id="accordionFlushExample">
                <div className="accordion-item">
                    <h2 className="accordion-header" id="flush-headingOne">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
                            Anti-Religious
                        </button>
                    </h2>
                    <div id="flush-collapseOne" className="accordion-collapse collapse" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
                        <div className="accordion-body">Content geared against a particular caste, religion, race, community, or tribe.
                            Example - Demeaning or destruction of religious symbols, deities, or institutions.
                            Malice or hatred toward a particular section of people.
                        </div>
                    </div>
                </div>
                <div className="accordion-item">
                    <h2 className="accordion-header" id="flush-headingTwo">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
                            Anti-National
                        </button>
                    </h2>
                    <div id="flush-collapseTwo" className="accordion-collapse collapse" aria-labelledby="flush-headingTwo" data-bs-parent="#accordionFlushExample">
                        <div className="accordion-body">Content that depicts the nation or national symbols disrespectfully
                            or derogatorily. Example - Statements or acts of hatred and contempt against the Indian Government
                            and disrespectful depiction of national symbols.
                        </div>
                    </div>
                </div>
                <div className="accordion-item">
                    <h2 className="accordion-header" id="flush-headingThree">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree">
                            Nudity and Obscenity
                        </button>
                    </h2>
                    <div id="flush-collapseThree" className="accordion-collapse collapse" aria-labelledby="flush-headingThree" data-bs-parent="#accordionFlushExample">
                        <div className="accordion-body">Content that contains explicit nudity (exposed genitals) during
                            intercourse in a sexual scenario or depicting genitalia to elicit sexual arousal in the viewer
                            is not allowed.
                        </div>
                    </div>
                </div>
                <div className="accordion-item">
                    <h2 className="accordion-header" id="flush-headingFour">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFour" aria-expanded="false" aria-controls="flush-collapseFour">
                            Animal Abuse/Torture
                        </button>
                    </h2>
                    <div id="flush-collapseFour" className="accordion-collapse collapse" aria-labelledby="flush-headingFour" data-bs-parent="#accordionFlushExample">
                        <div className="accordion-body">Content that depicts excessive cruelty or the torture of animals.</div>
                    </div>
                </div>
                <div className="accordion-item">
                    <h2 className="accordion-header" id="flush-headingFive">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFive" aria-expanded="false" aria-controls="flush-collapseFive">
                            Socially & Culturally Sensitive
                        </button>
                    </h2>
                    <div id="flush-collapseFive" className="accordion-collapse collapse" aria-labelledby="flush-headingFive" data-bs-parent="#accordionFlushExample">
                        <div className="accordion-body">Content that could upset the sentiments of specific segments of the public.
                            Any content that falls under these sub-categories will be edited.
                        </div>
                    </div>
                </div>
                <div className="accordion-item">
                    <h2 className="accordion-header" id="flush-headingSix">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseSix" aria-expanded="false" aria-controls="flush-collapseSix">
                            Tags
                        </button>
                    </h2>
                    <div id="flush-collapseSix" className="accordion-collapse collapse" aria-labelledby="flush-headingSix" data-bs-parent="#accordionFlushExample">
                        <div className="accordion-body">Tags are words attached to a title that describes the name,
                            genre, theme, actors, or any term that describes the show’s
                            content and will help viewers find the content they want. Any tags unrelated to the content
                            can be a grievance.
                        </div>
                    </div>
                </div>
                <div className="accordion-item">
                    <h2 className="accordion-header" id="flush-headingSeven">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseSeven" aria-expanded="false" aria-controls="flush-collapseSeven">
                            Age Rating
                        </button>
                    </h2>
                    <div id="flush-collapseSeven" className="accordion-collapse collapse" aria-labelledby="flush-headingSeven" data-bs-parent="#accordionFlushExample">
                        <div className="accordion-body">
                            <p paragraphPadding>The age-based classification allows viewers to select tiles appropriate to the
                                age group of the viewer. Any mismatch can be a grievance.
                            </p>
                            <table className="table table-hover accordionRowTable">
                                <thead>
                                    <tr>
                                        <th scope="col"><b>Rating</b></th>
                                        <th scope="col"><b>Details</b></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <th scope="row">U</th>
                                        <td>Content suitable for children</td>
                                    </tr>
                                    <tr>
                                        <th scope="row" className='accordionTh'>U/A 7+</th>
                                        <td>Content suitable for persons aged seven years and above, and
                                            can be viewed by a person under the age of seven years with parental guidance
                                        </td>
                                    </tr>
                                    <tr>
                                        <th scope="row" className='accordionTh'>U/A 13+</th>
                                        <td>Content suitable for persons aged thirteen years and above, and can
                                            be viewed by a person under the age of thirteen years with parental guidance
                                        </td>
                                    </tr>
                                    <tr>
                                        <th scope="row" className='accordionTh'>U/A 16+</th>
                                        <td>Content suitable for persons aged sixteen years and above, and can be
                                            viewed by a person under the age of sixteen years with parental guidance
                                        </td>
                                    </tr>
                                    <tr>
                                        <th scope="row">A</th>
                                        <td>Restricted to adults</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div className="accordion-item">
                    <h2 className="accordion-header" id="flush-headingEight">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseEight" aria-expanded="false" aria-controls="flush-collapseEight">
                            Content Descriptors
                        </button>
                    </h2>
                    <div id="flush-collapseEight" className="accordion-collapse collapse" aria-labelledby="flush-headingEight" data-bs-parent="#accordionFlushExample">
                        <div className="accordion-body">
                            <p paragraphPadding>Content Descriptors help viewers make informed choices by indicating the presence of the same. If the content of a title depicts the acts mentioned below but
                                does not have the appropriate content descriptors, these can be a grievance.
                            </p>
                            <ul className="list-group list-group-flush accordionList">
                                <li className="list-group-item">Violence</li>
                                <li className="list-group-item">Sexuality</li>
                                <li className="list-group-item">Nudity</li>
                                <li className="list-group-item">Foul Language</li>
                                <li className="list-group-item">Substance Use</li>
                                <li className="list-group-item">Alcohol</li>
                                <li className="list-group-item">Smoking</li>
                                <li className="list-group-item">Frightening Scenes</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="accordion-item">
                    <h2 className="accordion-header" id="flush-headingNine">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseNine" aria-expanded="false" aria-controls="flush-collapseNine">
                            Title synopsis
                        </button>
                    </h2>
                    <div id="flush-collapseNine" className="accordion-collapse collapse" aria-labelledby="flush-headingNine" data-bs-parent="#accordionFlushExample">
                        <div className="accordion-body">The title synopsis gives viewers an idea about the plot and nature of the content. If incorrect synopses are attached to a
                            title, a viewer can raise a grievance.
                        </div>
                    </div>
                </div>
                <div className="accordion-item">
                    <h2 className="accordion-header" id="flush-headingTen">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTen" aria-expanded="false" aria-controls="flush-collapseTen">
                            Parental access controls
                        </button>
                    </h2>
                    <div id="flush-collapseTen" className="accordion-collapse collapse" aria-labelledby="flush-headingTen" data-bs-parent="#accordionFlushExample">
                        <div className="accordion-body">Parental controls restrict mature content the parent deems inappropriate for their child. A viewer can file a grievance if mature content
                            is accessible through a profile despite pre-set restrictions.
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Ethics;