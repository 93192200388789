import React from 'react';
import ReactDOM from 'react-dom';

const Report = (props) => {
    return (
        <div className='first'>
            <h6>CONTENT GRIEVANCE REPORT</h6>
            <p className='paragraphPadding  mt-3 mb-3'>Our Content Grievance Report for the previous month is available here -</p>
            <table className="table tableFirst">
                <thead className="theadDark">
                    <tr className='tableFirstRow'>
                        <th scope="col" style={{padding:"15px"}}>SL. No.</th>
                        <th scope="col" style={{padding:"15px"}}>GRIEVANCES AND ACTIONS TAKEN</th>
                        <th scope="col" style={{padding:"15px"}}>NUMBER</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <th scope="row" style={{ textAlign: "center" , padding:"15px 30px"}}>1.</th>
                        <td style={{padding:"15px 30px"}}>Grievances pending at the beginning of the month</td>
                        <td style={{ textAlign: "center" , padding:"15px 30px"}}>{props?.reportData[0]?.data[0]?.value}</td>
                    </tr>
                    <tr>
                        <th scope="row" style={{ textAlign: "center" , padding:"15px 30px"}}>2.</th>
                        <td style={{padding:"15px 30px"}}>Grievances pending at the beginning of the month</td>
                        <td style={{ textAlign: "center" , padding:"15px 30px"}}>{props?.reportData[1]?.data[0]?.value}</td>
                    </tr>
                    <tr>
                        <th scope="row" style={{ textAlign: "center" , padding:"15px 30px"}}>3.</th>
                        <td style={{padding:"15px 30px"}}>Grievances disposed out of (1) above</td>
                        <td style={{ textAlign: "center" , padding:"15px 30px"}}>{props?.reportData[2]?.data[0]?.value}</td>
                    </tr>
                    <tr>
                        <th scope="row" style={{ textAlign: "center" , padding:"15px 30px"}}>4.</th>
                        <td style={{padding:"15px 30px"}}>Grievances disposed out of (2) above</td>
                        <td style={{ textAlign: "center" , padding:"15px 30px"}}>{props?.reportData[3]?.data[0]?.value}</td>
                    </tr>
                    <tr>
                        <th scope="row" style={{ textAlign: "center" , padding:"15px 30px"}}>5.</th>
                        <td style={{padding:"15px 30px"}}>Grievances pending at the end of the month (1+2-3-4)</td>
                        <td style={{ textAlign: "center" , padding:"15px 30px"}}>{props?.reportData[4]?.data[0]?.value}</td>
                    </tr>
                    <tr>
                        <th scope="row" style={{ textAlign: "center" , padding:"15px 30px"}}><b>6.</b></th>
                        <td style={{padding:"15px 30px"}}><b>Classifications of grievances disposed of</b></td>
                        <td style={{ textAlign: "center" , padding:"15px 30px"}}>{props?.reportData[5]?.data[0]?.value}</td>
                    </tr>
                    <tr>
                        <th scope="row" style={{ textAlign: "center" , padding:"15px 30px"}}>6 (a)</th>
                        <td style={{padding:"15px 30px"}}>Grievances not related to Code of Ethics</td>
                        <td style={{ textAlign: "center" , padding:"15px 30px"}}>{props?.reportData[6]?.data[0]?.value}</td>
                    </tr>
                    <tr>
                        <th scope="row" rowSpan={4} className="spacialTr"  style={{ textAlign: "center" , padding:"15px 30px"}}>6 (b)</th>
                        <td style={{padding:"15px 30px"}}>Grievances related to Code of Ethics</td>
                        <td style={{ textAlign: "center" , padding:"15px 30px"}}>{props?.reportData[7]?.data[0]?.value}</td>
                    </tr>
                    <tr>
                        <td style={{padding:"15px 30px"}}>(i) Agreed to by the publisher and action taken</td>
                        <td style={{ textAlign: "center" , padding:"15px 30px"}}>{props?.reportData[7]?.data[1]?.value}</td>
                    </tr>
                    <tr>
                        <td style={{padding:"15px 30px"}}>(ii) Not agreed to by the publisher</td>
                        <td style={{ textAlign: "center" , padding:"15px 30px"}}>{props?.reportData[7]?.data[2]?.value}</td>
                    </tr>
                    <tr>
                        <td style={{padding:"15px 30px"}}>(iii) Any other action taken</td>
                        <td style={{ textAlign: "center" , padding:"15px 30px"}}>{props?.reportData[7]?.data[3]?.value}</td>
                    </tr>
                    <tr>
                        <th scope="row" style={{ textAlign: "center" , padding:"15px 30px"}}><b>7.</b></th>
                        <td style={{padding:"15px 30px"}}><b>Orders, Directions and Advisories received from Central Government and Self-Regulatory Bodies</b></td>
                        <td style={{ textAlign: "center" , padding:"15px 30px"}}>{props?.reportData[8]?.data[0]?.value}</td>
                    </tr>
                    <tr>
                        <th scope="row" style={{ textAlign: "center" , padding:"15px 30px"}}>7 (a)</th>
                        <td style={{padding:"15px 30px"}}>Number of Orders, Directions and Advisories received</td>
                        <td style={{ textAlign: "center" , padding:"15px 30px"}}>{props?.reportData[9]?.data[0]?.value}</td>
                    </tr>
                    <tr>
                        <th scope="row" style={{ textAlign: "center" , padding:"15px 30px"}}>7 (b)</th>
                        <td style={{padding:"15px 30px"}}>Orders, Directions and Advisories complied to</td>
                        <td style={{ textAlign: "center" , padding:"15px 30px"}}>{props?.reportData[10]?.data[0]?.value}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    )
}

export default Report;