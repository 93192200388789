import apiConfig from "../config/apiConfig";

const getReportData = (callback) => {
  fetch(`${apiConfig.getApi.fetchReport}`)
    .then((response) => response.json())
    .then((data) => callback(data));
};

const postFormData = (payload, callback) => {
  fetch(`${apiConfig.postApi.saveFormData}`, {
    method: "POST",
    body: payload,
  })
    .then((response) => response.json())
    .then((data) => callback(data))
    .then(() => {
      document.getElementsByTagName("body")[0].classList.remove("overlay");
    })
    .catch((error) => {
        console.log(error)
        document.getElementsByTagName("body")[0].classList.remove("overlay");
        callback(error)
      })
};

export { getReportData, postFormData };
