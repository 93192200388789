import React from 'react';
import ReactDOM from 'react-dom';

const Tier = (params) => {
    return (
        <div className='first '>
            <h6 className='mb-0 mb-5'>The Tiered Grievance Redressal Program</h6>
            <h6 className='mb-0'>Tier 1: Self-Regulation by AMC+ Grievance Officer.</h6>
            <p className='paragraphPadding  mt-4 mb-4'>The complainant can reach out to the Grievance Officer with their complaints. An
                acknowledgment email will be sent to the complainant within 24 hours. After the complaint review,
                the Grievance Officer will respond to the complainant
                with the necessary information about the changes or clarifications if there were no changes within 15 days.
            </p>
            <h6 className='mb-0'>Tier 2: Escalation to DPCGC.</h6>
            <p className='paragraphPadding  mt-4 mb-4'>AMC is a member of the Self-Regulatory Body DPCGC.
                <br/>
                In case a complaint is not satisfactorily resolved by the Grievance Officer, the complainant can reach out to the DPCGC (self-regulatory body) at <a href="https://www.dpcgc.org/" target={'_blank'} rel="noreferrer" >https://www.dpcgc.org/</a>
            </p>
            <h6 className='mb-0'>Tier 3: Escalation to the Ministry of Information and Broadcasting (MIB).</h6>
            <p className='paragraphPadding  mt-4 mb-4'>An inter-departmental committee constituted by the Government will hear violations of the Code of Ethics
                that were not satisfactorily addressed by AMC+ Grievance Officer or if a complaint is directly referred to
                the Committee by the MIB.
            </p>
        </div>
    )
}

export default Tier;