import apiConstant from './env/env';

const apiConfig = {
    getApi: {
        fetchReport: `${apiConstant.API_URL}/fetch/report`,
    },
    postApi: {
        saveFormData: `${apiConstant.API_URL}/form/save`,
    },
};

export default apiConfig

