import React from 'react';
import ReactDOM from 'react-dom';


const Program = (props) => {
    const onSelect = (param) => {
        props.setSelected(param)
    }
    return (
        <div className='first'>
            <p className='paragraphPadding'>Any complaints related to the<button onClick={() => {
                    onSelect('Ethics')
                }} className='buttonForm' style={{ color: "#00A6A7" }}>CODE OF ETHICS</button>that include the suitability of the content or the age ratings,
                content descriptors, titles, synopsis, access controls available on the platform can be raised to the AMC+
                Grievance Officer by filling out this<button className='buttonForm' style={{ color: "#00A6A7" }} onClick={() => {
                    onSelect('Form')
                }}>form</button>.</p>
            <p className='paragraphPadding'>AMC+ is committed to resolving such instances expeditiously in
                accordance with applicable Indian law. Content grievance reporting is currently available in English only.</p>
            <p className='paragraphPadding'>
                Technical support and customer support will not be addressed in this process. Please address tech and customer support questions to customerservice@amcplus.com.
            </p>
            <h6 style={{ marginTop: '20px' }}>AMC+ CONTENT GRIEVANCE OFFICER</h6>
            <p className='para2 mt-3'>
                Our Grievance Officer for India is <a href="mailto:IndiaContentGrievances@amcnetworks.com" style={{ color: "#00A6A7", fontWeight:'bold', textDecoration: 'none' }}>Ankita Srivastava</a>.
                You can reach out to the Grievance Officer at the email address provided below.</p>
            <p className='paragraphPadding mt-4'>
                Grievance Officer : Ankita Srivastava <br /> <b>IndiaContentGrievances@amcnetworks.com</b></p>
            <p className='paragraphPadding'>
                Please include the following information in the email to the Grievance Officer.</p>
            <ul>
                <li>Your name, best email address to reach the person complaining</li>
                <li>Title, Season number, Episode number, (or title of movie/film), Timestamps </li>
                <li>Category of the complaint ( for example - age, rating, content descriptors )</li>
                <li>Summary of the complaint related to the Content</li>
                <li>Screenshot of the concerning content</li>
            </ul>
            <p className='paragraphPadding  mt-4'>
                Our Content Grievance Report for the previous month is available<button onClick={() => {
                    onSelect('Report')
                }} className='buttonForm' style={{ color: "#00A6A7" }}>HERE</button>.<br />
                You can read more about the categories related to Code of Ethics<button className='buttonForm' onClick={() => {
                    onSelect('Ethics')
                }} style={{ color: "#00A6A7" }}>HERE</button>.<br />
                Learn more about the escalation tier for the Grievance Redressal program<button className='buttonForm' onClick={() => {
                    onSelect('Tier')
                }} style={{ color: "#00A6A7" }}>HERE</button>.
            </p>
        </div>
    )
}

export default Program;