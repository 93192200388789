import React from 'react';
import ReactDOM from 'react-dom';
import logo from '../logo.png';

const Header = (params) => {
    return (
        <header className='header'>
            <div className='mb-3'><img className='img' src={logo} alt="Logo" /></div>
            <div className='headerContent'>CONTENT RELATED GRIEVANCE REDRESSAL PROGRAM</div>
        </header>
    )
}

export default Header;