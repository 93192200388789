import React from 'react';
import ReactDOM from 'react-dom';

const SideBar = (props) => {
    const onSelect = (param) => {
        props.setSelected(param)
    }
    return (
        <div className="list-group sideBar">
            <button onClick={() => {
                onSelect('Program')
            }} className={props.selected === 'Program' ? " sideBarButton sideBarStyling" : "sideBarButton"}>GRIEVANCE REDRESSAL PROGRAM</button>
            <button onClick={() => {
                onSelect('Report')
            }} className={props.selected === 'Report' ? "sideBarButton sideBarStyling" : "sideBarButton"}>CONTENT GRIEVANCE REPORT</button>
            <button onClick={() => {
                onSelect('Ethics')
            }} className={props.selected === 'Ethics' ? "sideBarButton sideBarStyling" : "sideBarButton"}>CODE OF ETHICS</button>
            <button onClick={() => {
                onSelect('Tier')
            }} className={props.selected === 'Tier' ? " sideBarButton sideBarStyling" : "sideBarButton"}>THE TIERED GRIEVANCE REDRESSAL PROGRAM</button>
        </div>
    )
}

export default SideBar;