import React from 'react';
import ReactDOM from 'react-dom';
import tick_icon from '../tick_icon.png';

const SuccessPage = (props) => {
    return (
        <div className='first sucess-page'>
            <h6 className='align-left'>AMC NETWORKS - CONTENT GRIEVANCE FORM</h6>
            <div><img className='tickIcon' src={tick_icon} alt="tick_icon" /></div>
            <h6 style={{ textAlign: "center" }}>Your Grievance has been recorded</h6>
            <p className='paragraphPadding SuccessPagePara' style={{ textAlign: "center" }}>We appreciate your feedback on the content. Our team will review your
                feedback and resolve your grievance soon. Thank you for your patience.
            </p>
            <button  type="button" onClick={() => {
                props.setSelected('Program')
            }} className="btn btn-outline-secondary goToHomePage align-right">GO TO HOME PAGE</button>
        </div>
    )
}

export default SuccessPage;